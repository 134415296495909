import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, Icon } from '@mui/material';

import { Images } from '@/constants/Images';
import { TodoAccountDataModel } from '@/models/Todo';

import SaveWithProBadge from '../utility/statuses/SaveWithProBadge';
import ToDoCategories from '../utility/statuses/ToDoCategories';

const ToDoItem = ({
  todo,
  index,
  setChosenToDo,
}: {
  todo: TodoAccountDataModel;
  index: number;
  setChosenToDo: (todo: TodoAccountDataModel) => void;
}) => {
  const { t } = useTranslation();
  return (
    <div
      key={todo.type}
      className="to-do-item cursor-pointer"
      style={{ '--animation-number': `${index}` } as React.CSSProperties}
      onClick={() => setChosenToDo(todo)}
    >
      <div className="d-flex jc-space-between mb8">
        <ToDoCategories category={todo.category} />
        <Icon className="text-faded">chevron_right</Icon>
      </div>
      <h4 className="min-h42">{todo?.content?.previewTitle}</h4>
      <p className="small text-faded min-h42">{todo?.content?.description}</p>
      <div className="mb8 mt8 text-center pos-rel">
        <img className="main-image" src={todo.content.images.desktop.preview ?? Images.toDoFallbackImage} alt="" />
        {todo.content.images.platformIcon && (
          <div className="pos-abs l6 t10">
            <img height="42px" className="pos-rel" src={todo.content.images.platformIcon} />
          </div>
        )}
        {todo.category === 'partners' && todo?.content?.promotionCode && (
          <div className="pos-abs l8 t8">
            <SaveWithProBadge />
          </div>
        )}
      </div>
      <Button className="btn-black-2 w100p ml0">{t('PLAYLISTING.VIEW-DETAILS')}</Button>
    </div>
  );
};

export default ToDoItem;
