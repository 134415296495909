import React, { useEffect, useRef, useState } from 'react';

import { Icon } from '@mui/material';

import { TodoAccountDataModel } from '@/models/Todo';

import ToDoItem from './ToDoItem';

const ToDoCategory = ({
  title,
  todos,
  setChosenToDo,
}: {
  title: string;
  todos: TodoAccountDataModel[];
  setChosenToDo: (todo: TodoAccountDataModel) => void;
}) => {
  const [scroll, setScroll] = useState<number>(0);
  const [disableRight, setDisableRight] = useState<boolean>(false);
  const [disableLeft, setDisableLeft] = useState<boolean>(true);
  const [showArrows, setShowArrows] = useState<boolean>(true);

  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!scrollRef?.current?.offsetWidth || !scrollRef?.current?.scrollWidth) return;
    if (scrollRef.current.scrollWidth > scrollRef.current.clientWidth) {
      setShowArrows(true);
    } else {
      setShowArrows(false);
    }
    if (scroll === 0) {
      setDisableLeft(true);
    } else {
      setDisableLeft(false);
    }
    if (scrollRef?.current?.scrollWidth - scrollRef?.current?.offsetWidth <= scroll) {
      setDisableRight(true);
    } else {
      setDisableRight(false);
    }
  }, [scroll]);

  const scrollRight = () => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollTo({
      top: 0,
      left: scrollRef.current.scrollLeft + scrollRef.current.clientWidth,
      behavior: 'smooth',
    });
  };

  const scrollLeft = () => {
    if (!scrollRef.current) return;
    scrollRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className="mb20">
      <div className="d-flex jc-space-between">
        <h3>{title}</h3>
        {showArrows && (
          <div>
            <Icon className={`cursor-pointer ${disableLeft ? 'text-faded' : ''}`} onClick={scrollLeft}>
              chevron_left
            </Icon>
            <Icon className={`cursor-pointer ${disableRight ? 'text-faded' : ''}`} onClick={scrollRight}>
              chevron_right
            </Icon>
          </div>
        )}
      </div>
      <div
        ref={scrollRef}
        className="d-flex gap20 mt10 overflow-scroll"
        onScroll={(e: React.UIEvent<HTMLDivElement | UIEvent>) => {
          return setScroll((e.target as HTMLDivElement).scrollLeft);
        }}
      >
        {todos?.map((todo, index) => (
          <div key={index}>
            <ToDoItem todo={todo} index={index} setChosenToDo={(todo) => setChosenToDo(todo)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToDoCategory;
